<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhNoDropSelector v-if="getterTablesBlockSections['sanctions'] !== undefined && getterTablesBlockSections['sanctions'].have_perms !== undefined  && getterTablesBlockSections['sanctions'].have_perms"
            :datablock="{
                id: 'section_sanction',
                title: $t('sanction'),
                label: getterTablesBlockSections['sanctions'] !== undefined && getterTablesBlockSections['sanctions'].count !== undefined ? getterTablesBlockSections['sanctions'].count + ' ' + $t('results') : 0 + ' ' + $t('results'),
            }"
        >
            <template v-slot:content>
                <component v-bind:is="SanctionView" :drop="drop"/>
            </template>
        </GhNoDropSelector>

        <component v-bind:is="HistoricPopup"/>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropDownField from 'fe-gh-drop-down-field-lib';
    import TableIncidence from './sanction/include/table_incidences.vue';
    import GhAction from "fe-gh-action-lib";
    import SanctionView from "@/view/sanction/sanction/sanction.vue";
    import HistoricPopup from '@/view/shared/popup_historic.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'sections_sanction',
        components: {
            GhNoDropSelector,
            GhAction,
            GhDropDownField,
        },
        computed: {
            ...mapGetters(['getterTablesSanction', 'getterTablesBlockSections'])
        },
        data(){
            return{
                haveData: false,
                drop: [],
                SanctionView: SanctionView,
                TableIncidence: TableIncidence,
                HistoricPopup: HistoricPopup,
            }
        },
        watch: {
            'getterTablesSanction'(data){
                Object.entries(data).forEach((i) => {
                    this.drop['key' + i[0]] = this.drop['key' + i[0]] !== undefined ? this.drop['key' + i[0]] : false;
                });

                this.$nextTick(() => {
                    this.methodFunction(this);
                })
            }
        },
        mounted() {
            let that = this;
            setTimeout(function(){
                document.querySelectorAll('[id^="drop_sanction_"]').forEach((element) => {
                    let childrens = element.children[0].children[0].children;

                    for (var i = 0; i < childrens.length; i++){
                        if(childrens[i]._prevClass !== 'link T14'){
                            element.children[0].children[0].children[i].setAttribute('title', childrens[i].innerHTML);
                        }
                    }

                    element.addEventListener('click', that.checkDrop);
                });
            },500);
        },
        async beforeMount(){
            await this.$store.dispatch('getCountPendingIncidences', '', {root: true});
            await this.$store.dispatch('getCompaniesPendingIncidences', '', {root: true});
            this.haveData = true;
        },
        beforeDestroy() {
            document.querySelectorAll('[id^="drop_sanction_"]').forEach((element) => {
                element.removeEventListener('click', this.checkDrop);
            });
        },
        methods:{
            methodFunction(){
                document.querySelectorAll('[id^="drop_sanction_"]').forEach((element) => {
                    let childrens = element.children[0].children[0].children;

                    for (var i = 0; i < childrens.length; i++){
                        if(childrens[i]._prevClass !== 'link T14'){
                            element.children[0].children[0].children[i].setAttribute('title', childrens[i].innerHTML);
                        }
                    }

                    element.addEventListener('click', this.checkDrop);
                });
            },
            checkDrop(event){
                let activeClass = event.srcElement.classList[0];
                if(activeClass === 'definition-field-icon-open' || activeClass === 'definition-field-icon-close') {
                    let splited = event.target.parentElement.parentElement.parentElement.parentElement.id.split('drop_sanction_')[1].split('_');
                    this.drop['key' + splited[0]] = activeClass === 'definition-field-icon-open';
                }
            }
        }
    }
</script>

<style>
    .definition-field_header-text{
        margin-right: 10px;
    }

    [id^="list_table_incidences_pending_"]{
        min-width: 800px !important;
    }

    [id^="drop_sanction_"] > .definition-field-content{
        max-width: 56%;
    }

    [id^="drop_sanction_"] > .definition-field-content > .definition-field-header{
        max-width: 100%;
    }

    [id^="drop_sanction_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_sanction_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_sanction_"] > .definition-field-content > .definition-field-header > .definition-field_header-text{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>
