<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="QualifiedInspections.sections[label['name']].have_perms" :key="'qualified_' + label.id + (label.name === 'qualified_inspections' ? '_' + getterRefreshValidateAll : '')"
                :datablock="{
                    id: 'qualified_inspections_' + label.id,
                    title: $t(label['name']),
                    label: QualifiedInspections.sections[label['name']].count + ' ' + $t(label['second_text']),
                }"
            >
                <template v-slot:actions>
                    <template v-if="QualifiedInspections.sections[label['name']].actions !== undefined && QualifiedInspections.sections[label['name']].actions.excel">
                        <GhAction
                            :dataction="{
                                id: 'qualified_sanctions_export_' + label.id,
                                text: $t('export_all'),
                                icon: require('../../assets/gh_new_dessign/descargar.svg'),
                            }"
                            @click="setExportExcel(label['name']);"
                        />
                    </template>
                </template>
                <template v-slot:content>
                    <component v-bind:is="label.component" :drop="drop"/>
                </template>
            </GhNoDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters, mapState} from "vuex";

    import qualified_inspections from "@/view/qualified_inspections/qualified_inspections/qualified_inspections.vue";

    export default {
        name: 'qualified_inspections',
        components: {
            GhNoDropSelector,
            GhAction
        },
        computed: {
            ...mapState(['QualifiedInspections']),
            ...mapGetters(['getterQualifiedInspectionsCompaniesIncidences','getterRefreshValidateAll'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'qualified_inspections', component: qualified_inspections, second_text: 'results'},
                ],
                drop: [],
                haveData: false
            }
        },
        watch: {
            'getterQualifiedInspectionsCompaniesIncidences'(data){
                Object.entries(data).forEach((i) => {
                    this.drop['key' + i[0]] = this.drop['key' + i[0]] !== undefined ? this.drop['key' + i[0]] : false;
                });

                document.querySelectorAll('[id^="drop_qualified_inspections_"]').forEach((element) => {
                    let childrens = element.children[0].children[0].children;

                    for (var i = 0; i < childrens.length; i++){
                        if(childrens[i]._prevClass !== 'link T14'){
                            element.children[0].children[0].children[i].setAttribute('title', childrens[i].innerHTML);
                        }
                    }

                    element.addEventListener('click', this.checkDrop);
                });
            }
        },
        mounted() {
            let that = this;
            setTimeout(function(){
                document.querySelectorAll('[id^="drop_qualified_inspections_"]').forEach((element) => {
                    let childrens = element.children[0].children[0].children;

                    for (var i = 0; i < childrens.length; i++){
                        if(childrens[i]._prevClass !== 'link T14'){
                            element.children[0].children[0].children[i].setAttribute('title', childrens[i].innerHTML);
                        }
                    }

                    element.addEventListener('click', that.checkDrop);
                });
            },500);
        },
        async beforeMount(){
            await this.$store.dispatch('getQualifiedInspectionsSections', '', {root: true});

            this.haveData = true;
        },
        methods: {
            async setExportExcel(type){
                await this.$store.dispatch('setExportExcelQualifiedInspections',{
                    type: type.replace('_', '-')
                },{root:true});
            },
            checkDrop(event){
                let activeClass = event.srcElement.classList[0];
                if(activeClass === 'definition-field-icon-open' || activeClass === 'definition-field-icon-close') {
                    let splited = event.target.parentElement.parentElement.parentElement.parentElement.id.split('drop_qualified_inspections_')[1].split('_');
                    this.drop['key' + splited[0]] = activeClass === 'definition-field-icon-open';
                }
            }
        }
    }
</script>

<style>
    .definition-field_header-text{
        margin-right: 10px;
    }

    [id^="list_table_incidences_pending_"]{
        min-width: 800px !important;
    }

    [id^="drop_qualified_inspections_"] > .definition-field-content{
        max-width: 56%;
    }

    [id^="drop_qualified_inspections_"] > .definition-field-content > .definition-field-header{
        max-width: 100%;
    }

    [id^="drop_qualified_inspections_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_qualified_inspections_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_qualified_inspections_"] > .definition-field-content > .definition-field-header > .definition-field_header-text{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>